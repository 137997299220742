<template>
  <AdminView>
    <h1 class="title-1">Activités Physiques Adaptées</h1>

    <div class="buttons-container">
      <div class="search">
        <Input title="Rechercher" v-model="search" />
      </div>

      <router-link :to="{ name: 'AddActivity' }">
        <Button title="Ajouter" type="secondary" />
      </router-link>
    </div>

    <table aria-describedby="MaterialTable">
      <thead>
        <tr>
          <th></th>
          <th>Image</th>
          <th class="sortable" @click="sort('name')">Titre</th>
          <th>Capacité fonctionnelle</th>
          <th>Matériel</th>
          <th class="sortable" @click="sort('intensity')">Intensité</th>
          <th class="sortable" @click="sort('duration')">Durée</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(activity, i) in activities" :key="i">
          <td class="edit">
            <router-link
              :to="{ name: 'Activity', params: { id: activity.id } }"
              title="Modifier"
            >
              <SVGEdit />
            </router-link>
          </td>
          <td class="image">
            <img :src="activity.image.url" v-if="activity.image.url" alt="activity" />
          </td>
          <td>{{ activity.title }}</td>
          <td>{{ formatCapacities(activity.functionalCapacities) }}</td>
          <td>{{ formatEquipments(activity.equipments) }}</td>
          <td>{{ $t(`difficultyLevels.${activity.intensity}`) }}</td>
          <td>{{ activity.duration }}s</td>
        </tr>
      </tbody>
    </table>
  </AdminView>
</template>

<script>
import { mapGetters } from "vuex"
import AdminView from "@/components/AdminView"
import Input from "@/components/Forms/Fields/Input"
import Button from "@/components/Button"
import SVGEdit from "@/assets/icons/edit.svg"

export default {
  name: "ActivitiesList",
  components: {
    AdminView,
    Input,
    Button,
    SVGEdit
  },
  data() {
    return {
      search: "",
      sortBy: "name"
    }
  },
  async created() {
    await this.$store.dispatch("admin/getActivities")
  },
  methods: {
    sort(type) {
      if (this.sortBy === type) {
        this.sortBy = "-" + type
      } else {
        this.sortBy = type
      }
    },
    formatCapacities(capacities) {
      return capacities
        .map((capacity) => this.$t(`functionalCapacity.${capacity.index}`))
        .join(", ")
    },
    formatEquipments(equipments) {
      return equipments.map((eq) => (eq ? eq.name : "")).join(", ")
    }
  },
  computed: {
    ...mapGetters({
      activities: "admin/activities"
    })
  }
}
</script>

<style lang="scss" scoped>
.buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 40px 0;

  div {
    flex: 0;
  }
}

.activities-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

table {
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;

  thead {
    font-size: 1.1rem;
    font-weight: 420;
    text-align: left;
    background: $main-color;
    color: $white-color;

    .sortable {
      cursor: pointer;
      transition: all 0.25s;

      &:hover {
        opacity: 0.75;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(2n) {
        background: #f7f7f7;
      }
    }
  }

  th,
  td {
    padding: 8px 10px;
    vertical-align: middle;
  }

  th {
    padding: 10px;
    user-select: none;
  }

  .image {
    padding: 2px;
    width: 50px;
    height: 38px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      border-radius: 5px;
    }
  }

  .icon {
    text-align: center;

    svg {
      width: 20px;
      color: $main-color;
    }
  }

  .edit {
    a {
      display: block;

      svg {
        width: 20px;
        transition: all 0.25s;
      }

      &:hover {
        svg {
          fill: $main-color;
        }
      }
    }
  }
}
</style>
